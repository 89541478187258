/**
 * @generated SignedSource<<590a3f04402b904288993c2e7fe2b9e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRecentSales_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_user" | "SharedMobileRelatedItem_user" | "SharedRelatedItemsWrapper_user">;
  readonly " $fragmentType": "SharedRecentSales_user";
};
export type SharedRecentSales_user$key = {
  readonly " $data"?: SharedRecentSales_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRecentSales_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedRecentSales_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRelatedItemsWrapper_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileRelatedItem_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "04f5e73361f9ef6426af48425ea00aa6";

export default node;
