import { FormattedMessage } from 'dibs-react-intl';
import { type ReactElement } from 'react';

export const sbSharedRefineMenuMessages = {
    minAndUp: ({ min }: { min: ReactElement }): ReactElement => (
        <FormattedMessage
            id="sb.SbSharedAppliedFiltersItem.inputRange.andUp"
            defaultMessage="{min} and up"
            values={{ min }}
        />
    ),
    minMaxFormattedMessage: ({
        isMobile,
        min,
        max,
    }: {
        isMobile?: boolean;
        min: ReactElement | number;
        max: ReactElement | number;
    }): ReactElement => (
        <FormattedMessage
            id="sb.SbSharedAppliedFiltersItem.min.max"
            defaultMessage="{min} {isMobile, select,
                    true {TO}
                    other {to}
                } {max}"
            values={{ min, max, isMobile }}
        />
    ),
    shippingFree: (
        <FormattedMessage
            id="sb.SbSharedAppliedFiltersItem.freeShipping"
            defaultMessage="Free Shipping"
        />
    ),
    shippingOptions: (
        <FormattedMessage
            id="sb.SbSharedAppliedFiltersItem.shippingOptions"
            defaultMessage="Shipping Options"
        />
    ),
};
