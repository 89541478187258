import { useEffect, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useFragment } from 'react-relay';
import { defineMessages, useIntl } from 'dibs-react-intl';

import { BasicSelect } from 'dibs-elements/exports/BasicSelect';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';

import { updateUriRef } from '../../actions/filterActions';
import { decode64 } from '../../utils/decode';
import { encode64 } from '../../utils/encode';
import { createNewUriRef, deleteQueryParams } from '../../utils/uriUtils';
import { SORT } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';

import { type SbRespSortItems_itemSearch$key } from './__generated__/SbRespSortItems_itemSearch.graphql';
import { type SbRespSortItems_user$key } from './__generated__/SbRespSortItems_user.graphql';

// styles
import styles from './SbRespSortItems.scss';

// These values are for internal use only
const additionalSortFilters = {
    recentlyListed: {
        displayName: 'Recently Listed',
        urlLabel: 'recently-listed',
    },
};

const messages = defineMessages({
    label: { id: 'sb.SbRespSortItemsComponent.label', defaultMessage: 'Sort by' },
});

const getRecentlyListed = (
    prevLinkReference: string
): {
    displayName: string;
    linkReference: string;
    urlLabel: string;
} => {
    const prevUrlSegment = decode64(prevLinkReference);
    const linkReference = createNewUriRef(prevUrlSegment, {
        sort: additionalSortFilters.recentlyListed.urlLabel,
    });
    const encodedLinkReference = encode64(linkReference);
    return {
        displayName: additionalSortFilters.recentlyListed.displayName,
        linkReference: encodedLinkReference,
        urlLabel: additionalSortFilters.recentlyListed.urlLabel,
    };
};

const itemFragment = graphql`
    fragment SbRespSortItems_itemSearch on ItemSearchQueryConnection {
        displayUriRef
        sort {
            options {
                urlLabel
                linkReference
                displayName
            }
            currentOption {
                urlLabel
            }
        }
    }
`;

const userFragment = graphql`
    fragment SbRespSortItems_user on User {
        hasBasicInternalPermission: hasPermission(name: "BASIC_INTERNAL")
        hasMerchandizingPermission: hasPermission(name: "BUYER_FE_MERCH_TOOL")
    }
`;

type RelayVariables = { isClient: boolean };
type Store = {
    relayVariables: {
        variables: RelayVariables;
    };
};

type Props = {
    itemSearch: SbRespSortItems_itemSearch$key;
    user: SbRespSortItems_user$key;
};
export const SbRespSortItems: FC<Props> = ({ itemSearch: itemSearchRef, user: userRef }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const relayVariables = useSelector<Store, RelayVariables>(state => ({
        isClient: state.relayVariables.variables.isClient,
    }));
    const { isClient } = relayVariables;
    const itemSearch = useFragment(itemFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);

    const selectedValueUrlLabel = itemSearch?.sort?.currentOption?.urlLabel || '';
    /**
     * GROWTH-6458: buyPageRelevance param is used for initial buy page rendering. Sort options should use regular 'relevance' sort param.
     */
    const selectedValue =
        selectedValueUrlLabel === 'buyPageRelevance' ? 'relevance' : selectedValueUrlLabel;

    // If user has engineering or merch team permission, show "recently-listed" sort option in dropdown
    const hasPermission = user?.hasBasicInternalPermission || user?.hasMerchandizingPermission;
    const initialOptions = itemSearch?.sort?.options || [];
    const recentlyListed = getRecentlyListed(initialOptions?.[0]?.linkReference || '');
    const options = hasPermission ? [...initialOptions, recentlyListed] : initialOptions;

    useEffect(() => {
        const hasRecentlyListed =
            selectedValueUrlLabel === additionalSortFilters.recentlyListed.urlLabel;
        /**
         * If user does not have permission to see recently-listed sort filter, but it is in urlParams, remove it.
         * Refetching should be initiated only after user data is fetched, because in the initial render user=null
         */
        if (isClient && hasRecentlyListed && !hasPermission) {
            const { displayUriRef } = itemSearch;
            const uriRef = deleteQueryParams(displayUriRef || '', SORT);
            //Removing '&sort=recently-listed' from history, cause going back in browser history negates effect of this 'if case'
            window.history.replaceState({}, '', handleLocaleUrl(uriRef, GLOBAL_CLIENT_ONLY_LOCALE));
            dispatch(
                updateUriRef({
                    uriRef,
                    filterName: undefined,
                    filterValue: undefined,
                    ga: undefined,
                    event: undefined,
                })
            );
        }
    }, [dispatch, isClient, itemSearch, hasPermission, selectedValueUrlLabel]);

    return (
        <div className={styles.container}>
            <BasicSelect
                ariaLabel={intl.formatMessage(messages.label)}
                size="medium"
                value={selectedValue}
                dataTn="sb-resp-sort-by"
                options={options.map(option => ({
                    value: option?.urlLabel || '',
                    label: option?.displayName || '',
                }))}
                onChange={event => {
                    const filterValue = options?.find(
                        option => option?.urlLabel === event.target.value
                    );
                    dispatch(
                        updateUriRef({
                            filterValue,
                            event,
                            uriRef: undefined,
                            filterName: undefined,
                            ga: undefined,
                        })
                    );
                }}
            />
        </div>
    );
};
