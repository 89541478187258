import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'dibs-elements/exports/Link';
import { Arrow } from '../../components/global/Arrow/Arrow';

import styles from './SharedPaginationArrowItem.scss';

function SharedPaginationArrowItem({ children, href, isLeft = false, onClick, dataTn }) {
    return (
        <Link
            className={classnames(styles.arrowItem, { [styles.isLeft]: isLeft })}
            dataTn={dataTn}
            onClick={onClick}
            href={href}
        >
            {!isLeft && children}
            <span className={styles.arrow}>
                <Arrow isLeft={isLeft} />
            </span>
            {isLeft && children}
        </Link>
    );
}

SharedPaginationArrowItem.propTypes = {
    children: PropTypes.node.isRequired,
    isLeft: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    dataTn: PropTypes.string,
    href: PropTypes.string,
};

export { SharedPaginationArrowItem };
