/**
 * @generated SignedSource<<3af48c0aadbb99ba8ab558d48986021e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilters_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterStoneCut_viewer" | "SbRespRefineMenuFilterStoneCut_viewer" | "SbSharedRefineMenuFilterArtSubject_viewer" | "SbSharedRefineMenuFilterCreator_viewer" | "SbSharedRefineMenuFilterItemLocation_viewer" | "SbSharedRefineMenuFilterMaterial_viewer" | "SbSharedRefineMenuFilterMetal_viewer" | "SbSharedRefineMenuFilterOrigin_viewer" | "SbSharedRefineMenuFilterStone_viewer">;
  readonly " $fragmentType": "SbSharedRefineMenuFilters_viewer";
};
export type SbSharedRefineMenuFilters_viewer$key = {
  readonly " $data"?: SbSharedRefineMenuFilters_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuFilters_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterMetal_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterStone_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterOrigin_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterCreator_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterMaterial_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterArtSubject_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterItemLocation_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterStoneCut_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterStoneCut_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "29bae1f903c9ed560faf07f2cfe66565";

export default node;
