import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { isMasqueradingAsBuyer } from 'dibs-cookie-jar';

// Actions
import { updateUriRef } from '../../../actions/filterActions';
import { getFilterValues } from '../../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

// Components
import { SbSharedRefineMenuFacet } from '../../SbSharedRefineMenu/SbSharedRefineMenuFacet/SbSharedRefineMenuFacet';
import { SbSharedRefineMenuInputCheckbox } from '../../SbSharedRefineMenu/SbSharedRefineMenuInput/SbSharedRefineMenuInputCheckbox';

// Constants
import {
    DISPLAY_PREFERENCE_OPTIONS,
    SOLD,
    SHOW_SOLD,
    NET_PRICE,
    STRATEGIC_PARTNER,
    MEASUREMENTS,
    RETURNABLE,
} from '../sbSharedRefineMenuConstants';
import {
    savePreference,
    setPreferenceValueFromLocalStorage,
} from '../../../actions/displayPreferenceActions';
import { trackFilterChange } from '../../../utils/tracking/searchBrowse/filterTracking';
const messages = defineMessages({
    title: {
        id: 'sb.SbSharedRefineMenu.filter.display.title',
        defaultMessage: 'Display',
    },
});

export function SbSharedRefineMenuDisplayPrefsSelectComponent({
    itemSearch,
    filters,
    user,
    showMeasurements,
    initiallyExpanded = false,
    isClient,
    onMount,
    onChange,
}) {
    useEffect(() => {
        onMount(MEASUREMENTS);
    }, [onMount]);
    const intl = useIntl();
    const filterName = 'display';
    const isVerifiedTrade = user?.isVerifiedTrade;
    const title = intl.formatMessage(messages.title);
    const showStrategicPartnerFilter =
        (isClient && isMasqueradingAsBuyer(document.cookie)) || user?.showStrategicPartnerFilter;
    const appliedFilters = itemSearch?.appliedFilters || [];
    const displayPreferenceFilters = filters
        ?.filter(({ name, values }) => DISPLAY_PREFERENCE_OPTIONS.includes(name) && values.length)
        .sort((a, b) => {
            return (
                DISPLAY_PREFERENCE_OPTIONS.indexOf(a.name) -
                DISPLAY_PREFERENCE_OPTIONS.indexOf(b.name)
            );
        });

    return (
        <>
            <SbSharedRefineMenuFacet
                title={title}
                key={filterName}
                storageFacetKey={filterName}
                initiallyExpanded={initiallyExpanded}
                trackingTrigger={filterName}
            >
                {displayPreferenceFilters?.map((filter, index) => {
                    // net price display preference is only for trade users
                    if (filter.name === NET_PRICE && !isVerifiedTrade) {
                        return null;
                    }

                    // only show strategic parnter filter to internal users
                    if (filter.name === STRATEGIC_PARTNER && !showStrategicPartnerFilter) {
                        return null;
                    }

                    // returnable display preference is only for non-trade users
                    if (filter.name === RETURNABLE && isVerifiedTrade) {
                        return null;
                    }

                    const appliedFilterUrlLabel = getFilterValues(appliedFilters, filter.name)?.[0]
                        ?.urlLabel;

                    const value = filter?.values?.[0];
                    let isChecked = appliedFilterUrlLabel === 'true';

                    // get measurements preference from localStorage
                    if (filter.name === MEASUREMENTS) {
                        isChecked = !!showMeasurements;
                    }

                    if (filter.name === SOLD) {
                        // if sold=false (client qp) or showSold=false (CDN qp), we want the Exclude Sold box to be checked. value of SOLD takes precedence over SHOW_SOLD
                        const showSoldUrlLabel = getFilterValues(appliedFilters, SHOW_SOLD)?.[0]
                            ?.urlLabel;
                        isChecked =
                            appliedFilterUrlLabel === 'false' ||
                            (appliedFilterUrlLabel !== 'true' && showSoldUrlLabel === 'false');
                    }

                    return (
                        <SbSharedRefineMenuInputCheckbox
                            key={`${filter.name}-${value.urlLabel}-${index}`}
                            value={value.urlLabel}
                            checked={isChecked}
                            linkReference={value.linkReference}
                            linkable={value.linkable}
                            onChange={(_checked, event) => {
                                onChange(filter.name, value, isChecked, event);
                                if (filter.name === MEASUREMENTS) {
                                    trackFilterChange({
                                        filterName: filter.name,
                                        isDeselected: isChecked,
                                    });
                                }
                            }}
                            text={value.displayName}
                        />
                    );
                })}
            </SbSharedRefineMenuFacet>
        </>
    );
}

SbSharedRefineMenuDisplayPrefsSelectComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    user: PropTypes.object,
    showMeasurements: PropTypes.bool,
    initiallyExpanded: PropTypes.bool,
    isClient: PropTypes.bool,
};

function mapStateToProps({ displayPreferences }) {
    return {
        showMeasurements: displayPreferences.showMeasurements,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onChange(filterName, filterValue, isDeselected, event) {
            const preferenceValue = isDeselected ? null : filterValue;
            dispatch(savePreference(filterName, preferenceValue));

            if (filterName !== MEASUREMENTS) {
                // measurements don't need to re-fetch anything
                dispatch(updateUriRef({ filterName, filterValue, isDeselected, event }));
            }
        },
        onMount(filterName) {
            dispatch(setPreferenceValueFromLocalStorage(filterName));
        },
    };
}

export const SbSharedRefineMenuDisplayPrefsSelect = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(SbSharedRefineMenuDisplayPrefsSelectComponent),
    {
        itemSearch: graphql`
            fragment SbSharedRefineMenuDisplayPrefsSelect_itemSearch on ItemSearchQueryConnection {
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
            }
        `,
        filters: graphql`
            fragment SbSharedRefineMenuDisplayPrefsSelect_filters on SearchBrowseFilter
            @relay(plural: true) {
                name
                values {
                    displayName
                    urlLabel
                    linkReference
                    linkable
                }
            }
        `,
        user: graphql`
            fragment SbSharedRefineMenuDisplayPrefsSelect_user on User {
                isVerifiedTrade
                showStrategicPartnerFilter: hasPermission(name: "STRATEGIC_PARTNER_FILTERING")
            }
        `,
    }
);
