/**
 * @generated SignedSource<<f7830f6af8023e1dc8efe418d70c1012>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuFilters_filters$data = ReadonlyArray<{
  readonly localizedFilterName: string | null;
  readonly name: string | null;
  readonly originalFilterName: string | null;
  readonly totalCount: number | null;
  readonly values: ReadonlyArray<{
    readonly code: string | null;
    readonly count: number | null;
    readonly displayName: string | null;
    readonly hexCode: string | null;
    readonly linkReference: string | null;
    readonly linkable: boolean | null;
    readonly properties: {
      readonly max: string | null;
      readonly min: string | null;
    } | null;
    readonly stats: ReadonlyArray<{
      readonly key: string | null;
      readonly values: string | null;
    } | null> | null;
    readonly urlLabel: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuFilterArtSize_filters" | "SbMobileRefineMenuFilterCategory_filters" | "SbMobileRefineMenuFilterDimensions_filters" | "SbRespRefineMenuFilterArtSize_filters" | "SbRespRefineMenuFilterCategory_filters" | "SbRespRefineMenuFilterDimensions_filters" | "SbSharedRefineMenuFilterRingSize_filters">;
  readonly " $fragmentType": "SbSharedRefineMenuFilters_filters";
}>;
export type SbSharedRefineMenuFilters_filters$key = ReadonlyArray<{
  readonly " $data"?: SbSharedRefineMenuFilters_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuFilters_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbSharedRefineMenuFilters_filters",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedFilterName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "originalFilterName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterCategory_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterCategory_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterDimensions_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterDimensions_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuFilterArtSize_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbMobileRefineMenuFilterArtSize_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilterRingSize_filters"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkReference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hexCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "stats",
          "kind": "LinkedField",
          "name": "stats",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "key",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "values",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterPropertiesType",
          "kind": "LinkedField",
          "name": "properties",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "min",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "max",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "990c9246aed894d47deea1e1157f510e";

export default node;
