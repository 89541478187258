/**
 * @generated SignedSource<<063076a3f90d951ebfef4110e4ca7d15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedRelatedItemsWrapper_item$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item">;
  readonly " $fragmentType": "SharedRelatedItemsWrapper_item";
}>;
export type SharedRelatedItemsWrapper_item$key = ReadonlyArray<{
  readonly " $data"?: SharedRelatedItemsWrapper_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedRelatedItemsWrapper_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SharedRelatedItemsWrapper_item",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesProvider_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "a4627f537de8d95150577331088dd5a0";

export default node;
