/**
 * @generated SignedSource<<37730737110294661462794b0eb805f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRefineMenuDisplayPrefsSelect_user$data = {
  readonly isVerifiedTrade: boolean | null;
  readonly showStrategicPartnerFilter: boolean | null;
  readonly " $fragmentType": "SbSharedRefineMenuDisplayPrefsSelect_user";
};
export type SbSharedRefineMenuDisplayPrefsSelect_user$key = {
  readonly " $data"?: SbSharedRefineMenuDisplayPrefsSelect_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRefineMenuDisplayPrefsSelect_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerifiedTrade",
      "storageKey": null
    },
    {
      "alias": "showStrategicPartnerFilter",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "STRATEGIC_PARTNER_FILTERING"
        }
      ],
      "kind": "ScalarField",
      "name": "hasPermission",
      "storageKey": "hasPermission(name:\"STRATEGIC_PARTNER_FILTERING\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b440e86ba9067ed9193e546d21a8949d";

export default node;
