import { type FC, type RefObject, type MouseEvent } from 'react';
import { graphql, useFragment } from 'react-relay';
import FavoritesItemProvider from 'dibs-portfolio-folder/exports/FavoritesItemProvider';
import { FavoritesItem } from '../../components/global/FavoriteIcons/FavoritesItem';
import { ItemTile } from 'dibs-search-product-tile/exports/ItemTile';
import { SRC_SET_SIZES_TILE, VERTICAL_OFFSET_MOBILE_IMG } from '../../constants/imageConstants';
import classnames from 'classnames';
import { useQuickView } from 'dibs-search-product-tile/exports/QuickViewProvider';

import styles from './SharedMobileRelatedItem.scss';

import { type SharedMobileRelatedItem_item$key } from './__generated__/SharedMobileRelatedItem_item.graphql';
import { type SharedMobileRelatedItem_user$key } from './__generated__/SharedMobileRelatedItem_user.graphql';
import { type FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';

const SMALL_TILE_TYPE = 'imageSmall';
export const X_SMALL_TILE_TYPE = 'imageXSmall';

type Props = {
    index: number;
    item: SharedMobileRelatedItem_item$key;
    user: SharedMobileRelatedItem_user$key | null | undefined;
    dataTn?: string;
    customClassName?: string | null;
    favoritesClassName?: string | null;
    favorites: FavoritesProviderChildrenProps;
    currency: string;
    wrapperRef?: RefObject<HTMLDivElement>;
    itemsPerRow?: number;
    showLightBox?: boolean;
    imageSize?: typeof X_SMALL_TILE_TYPE | typeof SMALL_TILE_TYPE;
    onClick?: (e: MouseEvent) => void;
    onContentVisible?: () => void;
    onContentLoaded?: () => void;
    ymalHeartsEnabled: boolean;
    hideRecentSoldPrice?: boolean;
};

function makeDummyWrapper<T>(props: T): FC<{ children: FC<T> }> {
    return ({ children }) => children(props);
}

export const SharedMobileRelatedItem: FC<Props> = ({
    index,
    item: itemRef,
    user: userRef,
    dataTn,
    customClassName,
    favoritesClassName,
    favorites,
    currency,
    wrapperRef,
    itemsPerRow = 2,
    showLightBox = false,
    imageSize = SMALL_TILE_TYPE,
    onClick,
    onContentVisible,
    onContentLoaded,
    ymalHeartsEnabled,
    hideRecentSoldPrice,
}) => {
    const item = useFragment(
        graphql`
            fragment SharedMobileRelatedItem_item on Item
            @argumentDefinitions(priceBookName: { type: "String" }) {
                isFurniture
                ...ItemTile_item @arguments(isTrade: $isTrade, priceBookName: $priceBookName)
                ...FavoritesItem_item
                ...FavoritesItemProvider_item
            }
        `,
        itemRef
    );
    const user = useFragment(
        graphql`
            fragment SharedMobileRelatedItem_user on User {
                canShowSellerName
                ...FavoritesItemProvider_user
            }
        `,
        userRef
    );

    const { canShowSellerName } = user || {};
    const canShowCreatorName = !canShowSellerName && item.isFurniture;
    const { openQuickView, closeQuickView } = useQuickView();

    const FavoritesItemProviderComponent = ymalHeartsEnabled
        ? FavoritesItemProvider
        : makeDummyWrapper({});

    return (
        <div
            className={classnames(styles.tileWrapper, customClassName)}
            data-tn={dataTn}
            ref={wrapperRef}
        >
            <FavoritesItemProviderComponent item={item} user={user || null}>
                {favoritesItemProps => (
                    <ItemTile
                        index={index}
                        item={item}
                        currency={currency}
                        itemsPerRow={itemsPerRow}
                        showSellerName={canShowSellerName}
                        showCreatorName={canShowCreatorName}
                        onClick={onClick}
                        onContentVisible={onContentVisible}
                        onContentLoaded={onContentLoaded}
                        showQuickView={!!openQuickView && !!closeQuickView}
                        imageSize={imageSize}
                        isMobile
                        useLoFiLazyLoader
                        hideImagePadding
                        disableContentMargin={showLightBox}
                        viewer={null}
                        srcSetSizes={SRC_SET_SIZES_TILE}
                        showMSKUSwatch={false}
                        imageLoadVerticalOffset={VERTICAL_OFFSET_MOBILE_IMG}
                        showMeasurements={false}
                        itemSearch={null}
                        showLightBox={showLightBox}
                        favoritesComponent={
                            ymalHeartsEnabled && (
                                <FavoritesItem
                                    wrapperClass={classnames(
                                        styles.favoritesWrapper,
                                        favoritesClassName
                                    )}
                                    heartIconWrapperClass={classnames(styles.heartIconWrapper, {
                                        [styles.showLightBox]: !!showLightBox,
                                    })}
                                    item={item}
                                    {...favorites}
                                    {...favoritesItemProps}
                                />
                            )
                        }
                        hideRecentSoldPrice={hideRecentSoldPrice}
                    />
                )}
            </FavoritesItemProviderComponent>
        </div>
    );
};
