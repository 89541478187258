import { type ReactNode } from 'react';
import { FormattedNumber } from 'dibs-react-intl';
import { handleMinimumFractionDigits } from 'dibs-buyer-price';
import {
    parseRangeDisplayValue,
    roundMinMax,
} from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { sbSharedRefineMenuMessages } from '../SbSharedRefineMenu/sbSharedRefineMenuMessages';

export const getPricePillValue = ({
    currency,
    displayName,
    isMobile,
}: {
    currency: string;
    displayName: string | null;
    isMobile: boolean;
}): ReactNode => {
    const [rawMin, rawMax] = parseRangeDisplayValue(displayName);
    const [min, max] = roundMinMax(rawMin, rawMax, true);
    const minValue = min === '*' || min === 0 ? 1 : min;
    const formattedMin = (
        <FormattedNumber
            value={minValue}
            style="currency"
            currency={currency}
            minimumFractionDigits={handleMinimumFractionDigits(minValue)}
        />
    );

    if (max === '*') {
        return sbSharedRefineMenuMessages.minAndUp({ min: formattedMin });
    } else {
        const formattedMax = (
            <FormattedNumber
                value={max}
                style="currency"
                currency={currency}
                minimumFractionDigits={handleMinimumFractionDigits(max)}
            />
        );
        return sbSharedRefineMenuMessages.minMaxFormattedMessage({
            isMobile,
            min: formattedMin,
            max: formattedMax,
        });
    }
};
