/**
 * @generated SignedSource<<f5c81037884adc909f73a8ebccddc0c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sharedRelatedItemsWrapperTracking_item$data = {
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly contemporaryTrackingString: string;
  readonly seller: {
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"shippingCostHelpers_item">;
  readonly " $fragmentType": "sharedRelatedItemsWrapperTracking_item";
};
export type sharedRelatedItemsWrapperTracking_item$key = {
  readonly " $data"?: sharedRelatedItemsWrapperTracking_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"sharedRelatedItemsWrapperTracking_item">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "sharedRelatedItemsWrapperTracking_item"
};

(node as any).hash = "9b79230453a95d64dd25d85f48d73785";

export default node;
