import { type Dispatch } from 'redux';
import { updateUriRef } from '../../actions/filterActions';
import { QP_PAGE } from '../../constants/queryParamConstants';
import {
    isDeliveredByFilter,
    SHIPPING_TO_FILTER,
} from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

export function getAppliedFilterValues<
    T extends { values: ReadonlyArray<Record<string, string | null> | null> | null }
>({
    appliedFilter,
    isBatchApplied,
}: {
    appliedFilter: T;
    isBatchApplied: boolean;
}): T['values'] | null | undefined {
    return isBatchApplied ? appliedFilter?.values?.slice(0, 1) : appliedFilter.values;
}

export function closeAppliedFilterHandler(dispatch: Dispatch) {
    return function ({
        filterName,
        filterValue,
        generatedUriRef,
        isBatchApplied,
        event,
    }: {
        filterName: string;
        filterValue?: Record<string, unknown> | null;
        generatedUriRef?: string | null;
        isBatchApplied: boolean;
        event: React.MouseEvent;
    }): void {
        const [segments, queryParams] = generatedUriRef ? generatedUriRef.split('?') : [];
        const searchParams = generatedUriRef ? new URLSearchParams(queryParams) : null;

        if (isBatchApplied && searchParams?.has(filterName)) {
            searchParams.delete(QP_PAGE);
            searchParams.delete(filterName);
            if (isDeliveredByFilter(filterName)) {
                searchParams.delete(SHIPPING_TO_FILTER);
            }
            dispatch(
                updateUriRef({
                    filterName,
                    filterValue,
                    uriRef: `${segments}?${decodeURIComponent(searchParams.toString())}`,
                    isDeselected: true,
                    event,
                    ga: undefined,
                })
            );
        } else {
            dispatch(
                updateUriRef({
                    filterName,
                    filterValue,
                    isDeselected: true,
                    event,
                    ga: undefined,
                    uriRef: undefined,
                })
            );
        }
    };
}

export function clearAllAppliedFiltersHandler(
    dispatch: Dispatch,
    props?: { itemSearch?: { clearAppliedFiltersLinkReference?: string | null } | null }
) {
    return function ({ event }: { event: React.MouseEvent }): void {
        const clearAppliedFiltersLinkReference =
            props?.itemSearch?.clearAppliedFiltersLinkReference;

        dispatch(
            updateUriRef({
                filterName: undefined,
                filterValue: { linkReference: clearAppliedFiltersLinkReference },
                event,
                ga: undefined,
                uriRef: undefined,
            })
        );
    };
}

type ValidatableAppliedFilterType = {
    canBeDismissed: boolean | null;
};

export const getValidSharedAppliedFilters = <T>(
    appliedFilters: ReadonlyArray<(T & ValidatableAppliedFilterType) | null>,
    additionalFilterRule?: (filter: T & ValidatableAppliedFilterType) => boolean
): ReadonlyArray<(T & ValidatableAppliedFilterType) | null> =>
    appliedFilters?.filter(filter =>
        additionalFilterRule
            ? filter?.canBeDismissed && additionalFilterRule(filter)
            : filter?.canBeDismissed
    ) || [];
